import { Component, OnInit } from '@angular/core';

@Component({
  template: `
    <div class="block-ui-template d-flex justify-content-center">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-center">
          <div class="spinner"></div>
          <img src="../../../assets/gifs/CityLoading.gif"/>
        </div>
        
        <div class="mt-2">Cargando...</div>
      </div>
    </div>
  `,
})
export class SpinnerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
