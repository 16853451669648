import { JwtHelperService } from '@auth0/angular-jwt';

export class Jwt {
  private static _instance: Jwt;

  private constructor() {}

  static get instance(): Jwt {
    if (!this._instance) {
      this._instance = new Jwt();
    }
    return this._instance;
  }

  public decode(token: string) {
    const helper = new JwtHelperService();
    return helper.decodeToken(token);
  }


  ////Test  
  public getExpirationDate(token: string) {

    const decodedToken = this.decode(token);
    if (decodedToken.exp === undefined) { return null; }
    const date = new Date(0);
    date.setUTCSeconds(decodedToken.exp);

    //Mon Jan 09 2023 20:24:27 GMT-0500 (hora de Ecuador)

    return date;
  }

}
