import { Component } from '@angular/core';

@Component({
  template: `
    <block-ui>
      
      <app-sidenav></app-sidenav>      

      <!-- Old Version not responsive -->
      <!-- <div class="d-flex" id="wrapper">
        <app-sidebar></app-sidebar>
        <div id="page-content-wrapper" class="full-layout">
          <app-header></app-header>
          <div class="container-fluid">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div> -->
    </block-ui>
  `,
})
export class FullLayoutComponent {
  constructor() {}
}
