import { Component } from '@angular/core';

@Component({
  template: `
    <block-ui>
      <div class="simple-layout">
        <main class="container-fluid px-0 mx-0">
          <router-outlet></router-outlet>
        </main>
      </div>
    </block-ui>
  `,
})
export class SimpleLayoutComponent {
  constructor() {}
}
