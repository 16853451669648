<div class="border-end bg-white" id="sidebar-wrapper">
  <div class="sidebar-heading">
    <a class="navbar-brand" [routerLink]="redirectLink">
      <img loading="lazy" src="assets/images/logo.png" alt="Budgets City Plans" title="Budgets City Plans" />
    </a>
  </div>
  <p-panelMenu
    styleClass="sidebar-menu"
    [multiple]="false"
    [model]="sidebarMenu"
  ></p-panelMenu>

  <!-- <div style="position: absolute; bottom: 0; left: 0;">    
    <span class="mdi mdi-account" style="color: #ffffff;" routerLink="/perfil"></span>
    <span class="mdi mdi-logout"></span>
  </div> -->

</div>
