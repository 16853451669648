import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CustomerGuard } from './core/guards/customer.guard';

import { AuthGuard } from './core/guards/auth.guard';

import { FullLayoutComponent } from './layout/full-layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout.component';
import { AdminGuard } from './core/guards/admin.guard';

import { RootGuard } from './core/guards/root.guard';


const APP_ROUTES: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'proyectos/lista',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    component: SimpleLayoutComponent,
    canActivate: [RootGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: '',
    component: FullLayoutComponent,
    // canActivate: [AuthGuard, CustomerGuard],
    canActivate: [CustomerGuard],
    children: [
      {
        path: 'ayuda',
        loadChildren: () => import('./modules/help/help.module').then((m) => m.HelpModule),
      },
      {
        path: 'inicio',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'perfil',
        loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'proyectos',
        loadChildren: () => import('./modules/project/project.module').then((m) => m.ProjectModule),
      },
      {
        path: 'catalogos',
        loadChildren: () => import('./modules/client-catalogue/client-catalogue.module').then((m) => m.ClientCatalogueModule),
      },
      {
        path: 'capitulos',
        loadChildren: () => import('./modules/client-chapter/client-chapter.module').then((m) => m.ClientChapterModule),
      },
      {
        path: 'rubros',
        loadChildren: () => import('./modules/client-item/client-item.module').then((m) => m.ClientItemModule),
      },
      {
        path: 'recursos',
        loadChildren: () => import('./modules/client-resource/client-resource.module').then((m) => m.ClientResourceModule),
      },
      {
        path: 'soporte',
        loadChildren: () => import('./modules/client-support/client-support.module').then((m) => m.ClientSupportModule),
      }
    ],
  },
  {
    path: 'admin',
    component: FullLayoutComponent,
    // canActivate: [AuthGuard],
    canActivate: [AdminGuard],
    children: [
      {
        path: 'capitulos',
        loadChildren: () => import('./modules/admin-chapter/admin-chapter.module').then(
          (m) => m.AdminChapterModule,
        ),
      },
      {
        path: 'catalogos',
        loadChildren: () => import('./modules/admin-catalogue/admin-catalogue.module').then(
          (m) => m.AdminCatalogueModule,
        ),
      },
      {
        path: 'recursos',
        loadChildren: () => import('./modules/admin-resource/admin-resource.module').then(
          (m) => m.AdminResourceModule,
        ),
      },
      {
        path: 'rubros',
        loadChildren: () => import('./modules/admin-item/admin-item.module').then(
          (m) => m.AdminItemModule,
        ),
      },
      {
        path: 'soporte',
        loadChildren: () => import('./modules/admin-support/admin-support.module').then(
          (m) => m.AdminSupportModule,
        ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
