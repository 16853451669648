import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../../shared/services/session.service';
import { Router } from '@angular/router';
import { Base } from '../../shared/utils/base';

@Injectable({
  providedIn: 'root',
})
export class CustomerGuard extends Base implements CanActivate {
  constructor(private _session: SessionService, private _router: Router) {
    super();
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this._session.isLoggedIn()) {
      if (this.storage.getRole() === 'customer' || this.storage.getRole() === 'subscriber') {
        return true;
      } else {
        return false;
      }
    } else {
      this._router.navigate(['/iniciar-sesion']);
      return false;
    }
  }
}
