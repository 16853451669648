import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '../../shared/utils/libraries/storage';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private _router: Router) {}

  public isLoggedIn(): boolean {
    return Storage.instance.getToken() !== null;
  }

  public signOut(): void {
    Storage.instance.removeToken();
    if (!this.isLoggedIn()) {
      this._router.navigate(['/iniciar-sesion']).then(() => {
        window.location.reload();
      });
    }
  }

  public destroySession(): void {
    Storage.instance.removeToken();
    if (!this.isLoggedIn()) {
      this._router.navigate(['/iniciar-sesion']);
    }
  }
}
