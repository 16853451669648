import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Storage } from '../../shared/utils/libraries/storage';
import { SessionService } from '../../shared/services/session.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _session: SessionService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = Storage.instance.token();
    if (token != null) {
      authReq = req.clone({
        headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token),
      });
    }
    return next
      .handle(authReq)
      .pipe(catchError((err) => this.handleError(err)));
  }

  public handleError(response: HttpErrorResponse) {
    // console.log('response', response.error)
    let error: string = '';
    switch (response.error.code) {
      case 'jwt_auth_invalid_token':
        error = 'Tu sesión a caducado';
        this._session.signOut();
        break;
      case 'jwt_auth_bad_request':
        error = 'El usuario no es correcto';
        break;
      default:
        // error = response.error.message;
        error = response.error;
        break;
    }
    return throwError(error);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
