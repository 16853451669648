import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FullLayoutComponent } from './layout/full-layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout.component';
import { SpinnerComponent } from './core/common/spinner.component';

import { BlockUIModule } from 'ng-block-ui';

import { authInterceptorProviders } from './core/interceptors/auth.interceptor';
import { HeaderComponent } from './layout/header/header.component';
import { ToastrModule } from 'ngx-toastr';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';

import { PanelMenuModule } from 'primeng/panelmenu';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    SimpleLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    SidenavComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,    
    BlockUIModule.forRoot({
      template: SpinnerComponent,
    }),
    ToastrModule.forRoot({
      enableHtml: true,
      timeOut: 10000,
    }),
    PanelMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,

  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
