import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { Token } from '../../shared/models/token.model';
import { SessionService } from '../../shared/services/session.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Base } from '../../shared/utils/base';
import { BreakpointObserver } from '@angular/cdk/layout';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends Base implements OnInit {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  public user: Token;
  public sidenavMenu: Array<any>;
  public redirectLink: string = this.storage.getRole() == 'budgetsadmin' ? '/admin/catalogos/lista' : '/proyectos/lista';
  public userRole = '';

  constructor(
    private observer: BreakpointObserver,
    private _router: Router,
    private _session: SessionService,
    private cd: ChangeDetectorRef) {    

    super();
    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        const token = this._session.isLoggedIn();
        if (token) this.user = this.token;        

        if(this.sidenav){
          if(evt.url.includes('/calculadora-presupuesto')
          || window.location.href.includes('/catalogue-designer')){
            this.sidenav.mode = 'over';
            this.sidenav.close();
          }
        }        
      }     
    });
    
  }

  ngAfterViewInit() {

    if(this.sidenav){

      this.observer.observe(['(max-width: 1024px)']).subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
  
          //Close Nav Var Just on designers components Open
          if(window.location.href.includes('/calculadora-presupuesto')
          || window.location.href.includes('/catalogue-designer')){
            this.sidenav.mode = 'over';
            this.sidenav.close();
          }else{
            this.sidenav.mode = 'side';
            this.sidenav.open();
          } 
        }
      });   
    }

    //Esto evita el sigueinte error
    //Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError
    this.cd.detectChanges();
  }
  

   

  ngOnInit(): void {



    this.setMenu();

    this.userRole = this.storage.getRole();
  }

  public signOut(): void {
    this._session.signOut();
  }

  public setMenu(): void {
    if (this.storage.getRole() == 'customer' || this.storage.getRole() == 'subscriber') {
      this.sidenavMenu = [
        {
          label: 'Proyectos',
          icon: 'pi pi-briefcase',
          routerLink: '/proyectos/lista',
        },
        {
          label: 'Catálogos',
          icon: 'pi pi-book',
          routerLink: '/catalogos/lista',
        },
        {
          label: 'Recursos',
          icon: 'pi pi-box',
          routerLink: '/recursos/lista',
        },
        {
          label: 'Comentarios',
          icon: 'pi pi-comments',
          routerLink: '/soporte',
        },
        {
          label: 'Ir a City Plans',
          icon: 'pi pi-fw pi-external-link',
          url: 'https://cityplans.net/',
        }, 
        {
          label: 'Ayuda',
          icon: 'pi pi-fw pi-info-circle',
          routerLink: '/ayuda',
        },
      ];
    }
    if (this.storage.getRole() == 'budgetsadmin' || this.storage.getRole() == 'budgetsadmintest') {
      this.sidenavMenu = [

        {
          label: 'Catálogos',
          icon: 'pi pi-book',
          routerLink: '/admin/catalogos/lista',
        },
        {
          label: 'Capítulos',
          icon: 'pi pi-list',
          routerLink: '/admin/capitulos/lista',
        },
        {
          label: 'Rubros',
          icon: 'pi pi-inbox',
          routerLink: '/admin/rubros/lista',
        },
        {
          label: 'Recursos',
          icon: 'pi pi-box',
          routerLink: '/admin/recursos/lista',
        },
        {
          label: 'Soporte',
          icon: 'pi pi-ticket',
          routerLink: '/admin/soporte/lista',
        }
      ];
    }
  }
}
