import { Spinner } from './libraries/spinner';
import { Storage } from './libraries/storage';
import { Token } from '../models/token.model';

export class Base {
  protected spinner: Spinner;
  protected token: Token;
  protected storage: Storage;

  constructor() {
    this.initData();
  }

  private initData(): void {
    this.token = Storage.instance.getToken();
    this.storage = Storage.instance;
    this.spinner = Spinner.instance;
  }
}
