import { Component, OnInit } from '@angular/core';
import { Base } from '../../shared/utils/base';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends Base implements OnInit {
  public sidebarMenu: Array<any>;
  public redirectLink: string = this.storage.getRole() == 'budgetsadmin' ? '/admin/catalogos/lista' : '/proyectos/lista';
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setMenu();
  }

  public setMenu(): void {
    if (this.storage.getRole() == 'customer') {
      this.sidebarMenu = [
        {
          label: 'Proyectos',
          icon: 'pi pi-briefcase',
          routerLink: '/proyectos/lista',
        },
        {
          label: 'Catálogos',
          icon: 'pi pi-book',
          routerLink: '/catalogos/lista',
        },
        {
          label: 'Recursos',
          icon: 'pi pi-box',
          routerLink: '/recursos/lista',
        },
        // {
        //   label: 'Gestiones',
        //   icon: 'pi pi-fw pi-cog',
        //   items: [
        //     {
        //       label: 'Capítulos',
        //       icon: 'mdi mdi-book-outline',
        //       routerLink: '/capitulos/lista',
        //     },
        //     {
        //       label: 'Rubros',
        //       icon: 'mdi mdi-bookshelf',
        //       routerLink: '/rubros/lista',
        //     },
        //     {
        //       label: 'Recursos',
        //       icon: 'mdi mdi-folder-open-outline',
        //       routerLink: '/recursos/lista',
        //     },
        //   ],
        // },        
        // {
        //   label: 'Documentación',
        //   icon: 'pi pi-fw pi-file',
        // },
        {
          label: 'Ir a City Plans',
          icon: 'pi pi-fw pi-external-link',
          url: 'https://cityplans.net/',
        }, 
        {
          label: 'Ayuda',
          icon: 'pi pi-fw pi-info-circle',
          routerLink: '/ayuda',
        },
      ];
    }
    if (this.storage.getRole() == 'budgetsadmin' || this.storage.getRole() == 'budgetsadmintest') {
      this.sidebarMenu = [
        {
          label: 'Gestiones',
          icon: 'pi pi-fw pi-cog',
          items: [
            {
              label: 'Catálogos',
              icon: 'mdi mdi-format-list-bulleted-type',
              routerLink: '/admin/catalogos/lista',
            },
            {
              label: 'Capítulos',
              icon: 'mdi mdi-book-outline',
              routerLink: '/admin/capitulos/lista',
            },
            {
              label: 'Rubros',
              icon: 'mdi mdi-bookshelf',
              routerLink: '/admin/rubros/lista',
            },
            {
              label: 'Recursos',
              icon: 'mdi mdi-folder-open-outline',
              routerLink: '/admin/recursos/lista',
            },
          ],
        },
      ];
    }
  }
}
