<nav class="navbar navbar-expand-lg">
  <div class="collapse navbar-collapse flex-grow-1 text-rigth">
    <ul class="navbar-nav ms-auto flex-nowrap">

      <li *ngIf="userRole==='budgetsadmin'" class="nav-item mt-3">
        <span class="mdi mdi-account" routerLink="/perfil"></span>
      </li>

      <li class="nav-item mt-3">
        <span class="mdi mdi-logout" (click)="signOut()"></span>
      </li>
    </ul>
  </div>
</nav>
