import { BlockUI, NgBlockUI } from 'ng-block-ui';

export class Spinner {
  private static _instance: Spinner;
  @BlockUI() blockUI: NgBlockUI;

  private constructor() {}
  static get instance(): Spinner {
    if (!this._instance) {
      this._instance = new Spinner();
    }
    return this._instance;
  }

  public start(message?: string) {
    if (!message) message = "";
    this.blockUI.start(message);
  }

  public stop() {
    this.blockUI.stop();
  }
}
