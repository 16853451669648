<mat-toolbar> 
  <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
    <i class="pi pi-bars" style="font-size: 1.5rem" *ngIf="!sidenav.opened"></i>
    <i class="pi pi-times" style="font-size: 1.5rem" *ngIf="sidenav.opened"></i>
  </button>
  <a class="navbar-brand" [routerLink]="redirectLink">
    <img loading="lazy" src="assets/images/logo.png" alt="Budgets City Plans" title="Budgets City Plans" width="170px"/>
  </a>

  <span class="example-spacer"></span>
  
  <button *ngIf="userRole!=='budgetsadmin'" mat-icon-button>
    <i class="pi pi-comments" style="font-size: 1.5rem" routerLink="/soporte" 
      pTooltip="Déjanos tus comentarios" tooltipPosition="bottom"></i>    
  </button>
  <button *ngIf="userRole!=='budgetsadmin'" mat-icon-button>
    <i class="pi pi-user" style="font-size: 1.5rem" routerLink="/perfil"
    pTooltip="Ver mi perfil" tooltipPosition="bottom"></i>    
  </button>
  <button mat-icon-button>
    <i class="pi pi-sign-out" style="font-size: 1.5rem" (click)="signOut()"
    pTooltip="Cerrar sesión" tooltipPosition="bottom"></i>    
  </button>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" mode="side" opened>
      <p-panelMenu
      styleClass="sidebar-menu"
      [multiple]="false"
      [model]="sidenavMenu"
    ></p-panelMenu>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="content">
          <div class="d-flex" id="wrapper">
            <div class="container-fluid">
              <router-outlet></router-outlet>
            </div>
          </div>          
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>