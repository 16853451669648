import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Token } from '../../shared/models/token.model';
import { SessionService } from '../../shared/services/session.service';
import { Base } from '../../shared/utils/base';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends Base implements OnInit {
  public user: Token;
  public menu = [];
  public userRole = '';
  
  constructor(
    private _router: Router,
    private _session: SessionService,
  ) { 
    super();
    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        const token = this._session.isLoggedIn();
        if (token) this.user = this.token;
      }
    });
  }

  ngOnInit(): void {
    this.menu = this.setMenu();
    this.userRole = this.storage.getRole();
  }

  public setMenu(): any {
    return [
      {
        name: 'Proyectos',
        path: '/proyectos/lista',
      },
      {
        name: 'Rubros',
        path: '/rubros/lista',
      },
      {
        name: 'Mi perfil',
        path: '/perfil',
      },
      {
        name: 'Ayuda',
        path: '/ayuda',
      },
      {
        name: 'Gestión de catálogos',
        path: '/admin/catalogo/lista',
      },
      {
        name: 'Gestión de rubros',
        path: '/admin/rubros/lista',
      },
      {
        name: 'Gestión de insumos',
        path: '/admin/recursos/lista',
      },
    ];
  }

  public signOut(): void {
    this._session.signOut();
  }
}
