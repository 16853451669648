import { Token } from '../../models/token.model';
import { Jwt } from './jwt';

const TOKEN_KEY = 'aDCTpP69sNyctBPIqj4O';
const TOKEN_ROLE_KEY = 'ChpjOF1AfxwKrXaJfNYP';
const TOKEN_SUBS_STATUS_KEY = 'fxwXaJfNF1ArPYKChpjO';


export class Storage {
  private static _instance: Storage;

  private constructor() {}

  static get instance(): Storage {
    if (!this._instance) {
      this._instance = new Storage();
    }
    return this._instance;
  }

  public removeToken(): void {
    localStorage.clear();
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public saveTokenRole(role: string): void {
    localStorage.removeItem(TOKEN_ROLE_KEY);
    localStorage.setItem(TOKEN_ROLE_KEY, btoa(role));
  }

  public saveTokenSubsStatus(subsStatus: string): void {
    localStorage.removeItem(TOKEN_SUBS_STATUS_KEY);
    localStorage.setItem(TOKEN_SUBS_STATUS_KEY, btoa(subsStatus));
  }

  public token() {
    return localStorage.getItem(TOKEN_KEY);
  }

  public role() {
    return localStorage.getItem(TOKEN_ROLE_KEY);
  }

  public subsStatus() {
    return localStorage.getItem(TOKEN_SUBS_STATUS_KEY);
  }

  public getToken(): Token {
    const token = localStorage.getItem(TOKEN_KEY);
    try {
      return Jwt.instance.decode(token);
    } catch (e) {
      return null;
    }
  }

  public getRole(): any {
    const token = localStorage.getItem(TOKEN_ROLE_KEY);
    try {
      return atob(token);
    } catch (e) {
      return null;
    }
  }

  public getSubsStatus(): any {
    const token = localStorage.getItem(TOKEN_SUBS_STATUS_KEY);
    try {
      return atob(token);
    } catch (e) {
      return null;
    }
  }

}
